<template>
  <div id="app" class="container text-center mx-auto mt-5">
    <span id="logo">vskr</span>
    <p class="mt-5">🚧🚧 Under construction 🚧🚧</p>
  </div>
</template>

<script>

export default {
  name: 'App'
}

</script>

<style>

#logo {
  font-family: 'Ubuntu', sans-serif;
  font-style: italic;
  font-size: 50px;
}

</style>
